import { Md5 } from 'ts-md5';

const USERNAME = '4b3e3c2f99046f92a61bab6775848577';
const PASSWORD = 'd7d4a3a46908aca52fd849af68971009';

export const useAuth = () => {
  const isLoggedIn = ref(localStorage.getItem('auth'));
  const isError = ref(false);

  const setLoginState = () => {
    isLoggedIn.value = localStorage.getItem('auth');
  };

  const login = (username: string, password: string) => {
    if (
      USERNAME !== Md5.hashStr(username) ||
      PASSWORD !== Md5.hashStr(password)
    ) {
      isError.value = true;
      return;
    }
    isError.value = false;
    localStorage.setItem('auth', 'true');
    setLoginState();
  };

  return {
    isLoggedIn,
    isError,
    login,
    setLoginState,
  };
};
