import { http } from '~/core/api/controllers';
import {
  Brand,
  City,
  Country,
  Currency,
  Pagination,
  Region,
  Response,
  Shop,
  ShopCategory,
  ShopListPayload,
  Warehouse,
} from '~/core/api/models';

type ShopList = {
  magazineList: Shop[];
  pagination: Pagination & { totalCount: number };
};

export const shopList = (
  payload: ShopListPayload = { page: 1, limit: 20, sort_by: 'popular' },
): Promise<Response<ShopList>> => {
  return http.post('/misc/magazine-list', payload);
};

export const shopInfo = (id: number): Promise<Response<Shop>> => {
  return http.post('/misc/magazine-info', { id });
};

export const shopCategoryList = (
  withCategory = false,
): Promise<Response<ShopCategory[]>> => {
  return http.post('/misc/magazine-category-list', {
    with_category: Number(withCategory),
  });
};

export const brandList = (): Promise<Response<Brand[]>> => {
  return http.get('/misc/tag-list');
};

export const countryList = (
  withCategory = false,
): Promise<Response<Country[]>> => {
  return http.post('/misc/country-list', {
    with_category: Number(withCategory),
  });
};

export const regionList = (
  countryId: Country['id'],
): Promise<Response<Region[]>> => {
  return http.post('/misc/region-list', { country_id: countryId });
};

export const cityList = (
  countryId?: Country['id'],
  regionId?: Region['id'],
): Promise<Response<City[]>> => {
  return http.post('/misc/city-list', {
    country_id: countryId,
    region_id: regionId,
  });
};

export const warehouseList = (): Promise<Response<Warehouse[]>> => {
  return http.get('/misc/warehouse-list');
};

export const currencyList = (): Promise<Response<Currency[]>> => {
  return http.get('/misc/currency-list');
};

export const getSettingList = (): Promise<
  Response<Record<string, boolean>>
> => {
  return http.get('/misc/setting-list');
};
