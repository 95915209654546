import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { Response } from '~/core/api/models';

let client: AxiosInstance;

export function setClient(axiosInstance: AxiosInstance) {
  client = axiosInstance;
}

export const http = {
  get<T>(path: string, config?: AxiosRequestConfig): Promise<Response<T>> {
    return client.get(path, config);
  },

  post<T, R>(
    path: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<Response<R>> {
    return client.post(path, data, config);
  },

  put<T, R>(
    path: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<Response<R>> {
    return client.put(path, data, config);
  },

  patch<T, R>(
    path: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<Response<R>> {
    return client.patch(path, data, config);
  },

  delete<R>(path: string, config?: AxiosRequestConfig): Promise<Response<R>> {
    return client.delete(path, config);
  },

  options<R>(path: string, config?: AxiosRequestConfig): Promise<Response<R>> {
    return client.options(path, config);
  },

  head<R>(path: string, config?: AxiosRequestConfig): Promise<Response<R>> {
    return client.head(path, config);
  },

  request<R>(config: AxiosRequestConfig): Promise<Response<R>> {
    return client.request(config);
  },
};
