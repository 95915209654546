<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
const isPonyMarket = location.host === 'pony.market';

useHead({
  title: `${
    isPonyMarket ? 'Pony.market' : 'Storebusters'
  } | Выгодные онлайн покупки за рубежом с быстрой доставкой`,
});
</script>
