import { default as indexvhNSNUie19Meta } from "/var/www/test/pony.market/pages/401/index.vue?macro=true";
import { default as indexWkbnvtXX3RMeta } from "/var/www/test/pony.market/pages/contacts/index.vue?macro=true";
import { default as indexEtyKInHk5WMeta } from "/var/www/test/pony.market/pages/delivery-terms/index.vue?macro=true";
import { default as indexJ11wV4ztE3Meta } from "/var/www/test/pony.market/pages/index.vue?macro=true";
import { default as index43Dj6gUsx8Meta } from "/var/www/test/pony.market/pages/limitations/index.vue?macro=true";
import { default as indexupEkEdZywLMeta } from "/var/www/test/pony.market/pages/limits/index.vue?macro=true";
import { default as indexPXpO4FsIt8Meta } from "/var/www/test/pony.market/pages/payment-methods/index.vue?macro=true";
import { default as fails3PrVgTtfcMeta } from "/var/www/test/pony.market/pages/payment/callback/fail.vue?macro=true";
import { default as successjRGLvpS1mpMeta } from "/var/www/test/pony.market/pages/payment/callback/success.vue?macro=true";
import { default as fail0Uq5AT0HXqMeta } from "/var/www/test/pony.market/pages/payment/fail.vue?macro=true";
import { default as indexn3xYreO5SFMeta } from "/var/www/test/pony.market/pages/payment/index.vue?macro=true";
import { default as success1Endegv0NkMeta } from "/var/www/test/pony.market/pages/payment/success.vue?macro=true";
import { default as indexNG2Z8IIcsbMeta } from "/var/www/test/pony.market/pages/shipping-calculator/Backup/index.vue?macro=true";
import { default as indexoDDLjaKy62Meta } from "/var/www/test/pony.market/pages/shipping-calculator/index.vue?macro=true";
import { default as _91id_93VgBm7u1KfaMeta } from "/var/www/test/pony.market/pages/shops/[id].vue?macro=true";
import { default as indexC0d7HxwMBnMeta } from "/var/www/test/pony.market/pages/shops/index.vue?macro=true";
import { default as index6u69OXVXexMeta } from "/var/www/test/pony.market/pages/techworks/index.vue?macro=true";
import { default as index7JuzdBsYyzMeta } from "/var/www/test/pony.market/pages/terms/index.vue?macro=true";
import { default as privacyDbGfwrgIS3Meta } from "/var/www/test/pony.market/pages/terms/privacy.vue?macro=true";
export default [
  {
    name: indexvhNSNUie19Meta?.name ?? "401",
    path: indexvhNSNUie19Meta?.path ?? "/401",
    meta: indexvhNSNUie19Meta || {},
    alias: indexvhNSNUie19Meta?.alias || [],
    redirect: indexvhNSNUie19Meta?.redirect || undefined,
    component: () => import("/var/www/test/pony.market/pages/401/index.vue").then(m => m.default || m)
  },
  {
    name: indexWkbnvtXX3RMeta?.name ?? "contacts",
    path: indexWkbnvtXX3RMeta?.path ?? "/contacts",
    meta: indexWkbnvtXX3RMeta || {},
    alias: indexWkbnvtXX3RMeta?.alias || [],
    redirect: indexWkbnvtXX3RMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony.market/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexEtyKInHk5WMeta?.name ?? "delivery-terms",
    path: indexEtyKInHk5WMeta?.path ?? "/delivery-terms",
    meta: indexEtyKInHk5WMeta || {},
    alias: indexEtyKInHk5WMeta?.alias || [],
    redirect: indexEtyKInHk5WMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony.market/pages/delivery-terms/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ11wV4ztE3Meta?.name ?? "index",
    path: indexJ11wV4ztE3Meta?.path ?? "/",
    meta: indexJ11wV4ztE3Meta || {},
    alias: indexJ11wV4ztE3Meta?.alias || [],
    redirect: indexJ11wV4ztE3Meta?.redirect || undefined,
    component: () => import("/var/www/test/pony.market/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index43Dj6gUsx8Meta?.name ?? "limitations",
    path: index43Dj6gUsx8Meta?.path ?? "/limitations",
    meta: index43Dj6gUsx8Meta || {},
    alias: index43Dj6gUsx8Meta?.alias || [],
    redirect: index43Dj6gUsx8Meta?.redirect || undefined,
    component: () => import("/var/www/test/pony.market/pages/limitations/index.vue").then(m => m.default || m)
  },
  {
    name: indexupEkEdZywLMeta?.name ?? "limits",
    path: indexupEkEdZywLMeta?.path ?? "/limits",
    meta: indexupEkEdZywLMeta || {},
    alias: indexupEkEdZywLMeta?.alias || [],
    redirect: indexupEkEdZywLMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony.market/pages/limits/index.vue").then(m => m.default || m)
  },
  {
    name: indexPXpO4FsIt8Meta?.name ?? "payment-methods",
    path: indexPXpO4FsIt8Meta?.path ?? "/payment-methods",
    meta: indexPXpO4FsIt8Meta || {},
    alias: indexPXpO4FsIt8Meta?.alias || [],
    redirect: indexPXpO4FsIt8Meta?.redirect || undefined,
    component: () => import("/var/www/test/pony.market/pages/payment-methods/index.vue").then(m => m.default || m)
  },
  {
    name: fails3PrVgTtfcMeta?.name ?? "payment-callback-fail",
    path: fails3PrVgTtfcMeta?.path ?? "/payment/callback/fail",
    meta: fails3PrVgTtfcMeta || {},
    alias: fails3PrVgTtfcMeta?.alias || [],
    redirect: fails3PrVgTtfcMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony.market/pages/payment/callback/fail.vue").then(m => m.default || m)
  },
  {
    name: successjRGLvpS1mpMeta?.name ?? "payment-callback-success",
    path: successjRGLvpS1mpMeta?.path ?? "/payment/callback/success",
    meta: successjRGLvpS1mpMeta || {},
    alias: successjRGLvpS1mpMeta?.alias || [],
    redirect: successjRGLvpS1mpMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony.market/pages/payment/callback/success.vue").then(m => m.default || m)
  },
  {
    name: fail0Uq5AT0HXqMeta?.name ?? "payment-fail",
    path: fail0Uq5AT0HXqMeta?.path ?? "/payment/fail",
    meta: fail0Uq5AT0HXqMeta || {},
    alias: fail0Uq5AT0HXqMeta?.alias || [],
    redirect: fail0Uq5AT0HXqMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony.market/pages/payment/fail.vue").then(m => m.default || m)
  },
  {
    name: indexn3xYreO5SFMeta?.name ?? "payment",
    path: indexn3xYreO5SFMeta?.path ?? "/payment",
    meta: indexn3xYreO5SFMeta || {},
    alias: indexn3xYreO5SFMeta?.alias || [],
    redirect: indexn3xYreO5SFMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony.market/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: success1Endegv0NkMeta?.name ?? "payment-success",
    path: success1Endegv0NkMeta?.path ?? "/payment/success",
    meta: success1Endegv0NkMeta || {},
    alias: success1Endegv0NkMeta?.alias || [],
    redirect: success1Endegv0NkMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony.market/pages/payment/success.vue").then(m => m.default || m)
  },
  {
    name: indexNG2Z8IIcsbMeta?.name ?? "shipping-calculator-Backup",
    path: indexNG2Z8IIcsbMeta?.path ?? "/shipping-calculator/Backup",
    meta: indexNG2Z8IIcsbMeta || {},
    alias: indexNG2Z8IIcsbMeta?.alias || [],
    redirect: indexNG2Z8IIcsbMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony.market/pages/shipping-calculator/Backup/index.vue").then(m => m.default || m)
  },
  {
    name: indexoDDLjaKy62Meta?.name ?? "shipping-calculator",
    path: indexoDDLjaKy62Meta?.path ?? "/shipping-calculator",
    meta: indexoDDLjaKy62Meta || {},
    alias: indexoDDLjaKy62Meta?.alias || [],
    redirect: indexoDDLjaKy62Meta?.redirect || undefined,
    component: () => import("/var/www/test/pony.market/pages/shipping-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93VgBm7u1KfaMeta?.name ?? "shops-id",
    path: _91id_93VgBm7u1KfaMeta?.path ?? "/shops/:id()",
    meta: _91id_93VgBm7u1KfaMeta || {},
    alias: _91id_93VgBm7u1KfaMeta?.alias || [],
    redirect: _91id_93VgBm7u1KfaMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony.market/pages/shops/[id].vue").then(m => m.default || m)
  },
  {
    name: indexC0d7HxwMBnMeta?.name ?? "shops",
    path: indexC0d7HxwMBnMeta?.path ?? "/shops",
    meta: indexC0d7HxwMBnMeta || {},
    alias: indexC0d7HxwMBnMeta?.alias || [],
    redirect: indexC0d7HxwMBnMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony.market/pages/shops/index.vue").then(m => m.default || m)
  },
  {
    name: index6u69OXVXexMeta?.name ?? "techworks",
    path: index6u69OXVXexMeta?.path ?? "/techworks",
    meta: index6u69OXVXexMeta || {},
    alias: index6u69OXVXexMeta?.alias || [],
    redirect: index6u69OXVXexMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony.market/pages/techworks/index.vue").then(m => m.default || m)
  },
  {
    name: index7JuzdBsYyzMeta?.name ?? "terms",
    path: index7JuzdBsYyzMeta?.path ?? "/terms",
    meta: index7JuzdBsYyzMeta || {},
    alias: index7JuzdBsYyzMeta?.alias || [],
    redirect: index7JuzdBsYyzMeta?.redirect || undefined,
    component: () => import("/var/www/test/pony.market/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: privacyDbGfwrgIS3Meta?.name ?? "terms-privacy",
    path: privacyDbGfwrgIS3Meta?.path ?? "/terms/privacy",
    meta: privacyDbGfwrgIS3Meta || {},
    alias: privacyDbGfwrgIS3Meta?.alias || [],
    redirect: privacyDbGfwrgIS3Meta?.redirect || undefined,
    component: () => import("/var/www/test/pony.market/pages/terms/privacy.vue").then(m => m.default || m)
  }
]