import axios from 'axios';
import { Md5 } from 'ts-md5';
import { setClient } from '~/core/api/controllers/http-common';

export default defineNuxtPlugin(() => {
  const {
    public: { apiUrl },
  } = useRuntimeConfig();

  const baseURL = apiUrl;
  const sid = 6;
  const token = 'dde720d86821b84443ee9e1ffce07887';
  const pid = '1';
  const hash = Md5.hashStr(pid + token);

  const config = {
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      pid,
      hash,
      sid,
    },
  };

  const client = axios.create(config);

  client.interceptors.request.use(
    (config) => {
      config.headers = config.headers ?? {};
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  setClient(client);
});
